import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import auth0 from "auth0-js";

function App() {
  var ssoCompleted = false;

  const {loginWithRedirect} = useAuth0();

  const ssoConnection = "Mobile-SSO";
  const webAuth = new auth0.WebAuth({
    domain: "auth.dev.comparethemarket.com.au",
    clientID: "TjLonBJKQHNR55wQ6dZ6lXnLZzsn1xc2",
    redirectUri:
      "https://test-simples-sso.pages.dev?sso_completed=true",
    response_type: "id_token",
  });
  const searchParams = new URLSearchParams(document.location.search);
  ssoCompleted = Boolean(searchParams.get("sso_completed"));

  console.log(`ssoCompleted: ${ssoCompleted}`);

  const loginAttempt = async(sso) => {
    try {
      webAuth.login(
        {
          realm: ssoConnection,
          email: `${sso.userId}@ctm.sso`,
          password: sso.accessToken,
          responseType: "id_token"
        },
        (error) => {
          console.log(`Error sso login: ${JSON.stringify(error)}`);
        }
      );
      
    } catch (error) {
      console.log(`Error occurred while attempt sso: ${error}`);
    }
  }

  window.addEventListener("SSOEvent", (e) => {
    e.stopImmediatePropagation();
    loginAttempt(e.detail);
  });

  if(!ssoCompleted){
    return (
      <div>
        Wait for event
      </div>
    );
  }
  if(ssoCompleted){
    console.log(`morris loginWithRedirect`);
    loginWithRedirect({
      authorizationParams: {
        connection: ssoConnection,
        redirect_uri: `https://dev.comparethemarket.com.au/account/profile`,
      },
    });
  }
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
